import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { ApruvRateService, ReviewService } from '@apruv-client/services';
import { AddReviewComponent } from '../add-review/add-review.component';
import { PaymentMethodComponent } from '../payment-method/payment-method.component';
import { ThankYouComponent } from '../thank-you/thank-you.component';
import { TipTechComponent } from '../tip-tech/tip-tech.component';
import { TippingComponent } from '../tipping/tipping.component';
import { ReviewDoneComponent } from '../review-done/review-done.component';
import { NegativeReviewComponent } from "../negative-review/negative-review.component";

@Component({
  selector: 'app-review-old',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ButtonModule, RouterOutlet, AddReviewComponent, PaymentMethodComponent, ThankYouComponent, TipTechComponent, TippingComponent,
    ReviewDoneComponent, NegativeReviewComponent],
  templateUrl: './review-old.component.html',
  styleUrl: './review-old.component.scss',
})
export class ReviewOldComponent implements OnInit {
  isV2Review = false;
  constructor(private apruvRateService: ApruvRateService,
    public reviewService: ReviewService, private route: ActivatedRoute, private router: Router,
  ) {
    this.isV2Review = this.router.url.split('/').includes('v2');
    this.reviewService.oldV2Version = this.isV2Review;
    this.reviewService.oldReview = true;
  }

  ngOnInit(): void {
    const appointmentId = this.route.snapshot?.params['appointmentId'];
    const companyName = this.route.snapshot?.params['companyName'];
    this.reviewService.appointmentId = appointmentId;
    this.reviewService.companyName = companyName;
  }
}

@switch (reviewService.reviewStep) {
    @case ('add') {
        <app-add-review></app-add-review>
    }
    @case ('negative') {
        <app-negative-review></app-negative-review>
    }
    @case ('payment') {
        <app-payment-method></app-payment-method>
    }
    @case ('tip-tech') {
        <app-tip-tech></app-tip-tech>
    }
    @case ('tipping') {
        <app-tipping></app-tipping>
    }
    @case ('thank-you') {
        <app-thank-you></app-thank-you>
    }
    @case ('done') {
        <app-review-done></app-review-done>
    }
    @default {
        <app-add-review></app-add-review>
    }
}